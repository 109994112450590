import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import is from 'is_js';
// import FalconRoutes from 'routes';
import SIRoutes from 'si/routes';
import { CloseButton } from 'components/common/Toast';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
// import SettingsPanel from 'components/settings-panel/SettingsPanel';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { useAppContext } from 'Main';
import { useAuthContext } from 'si/auth/AuthProvider';
import AuthRoutes from 'si/auth';

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useAppContext();
  const { authenticated } = useAuthContext();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  if (authenticated) {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <SIRoutes />
        {/* Comment out next two lines if you don't need dynamic settings */}
        {/* <SettingsToggle /> */}
        {/* <SettingsPanel /> */}
        {/* Note that the top menu hasn't been overridden - so that's considerable work to do */}
        <ToastContainer
          closeButton={CloseButton}
          icon={false}
          position={toast.POSITION.BOTTOM_LEFT}
        />
      </Router>
    );
  } else {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <AuthRoutes />
      </Router>
    );
  }
};

export default App;
