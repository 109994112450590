import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';

const LiveJobs = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">Live Job Progress</h3>
            <p className="lead">Replace the CW form here.</p>
            <p>
              The goal here is to be able to see detailed progress of individual
              jobs. This would also let us replace the CW form, which will make
              customization a lot faster.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LiveJobs;
