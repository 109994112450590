import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
// import { getUsers } from 'si/api/users';
import { useAuthContext } from 'si/auth/AuthProvider';

const Create = () => {
  const [formData, setFormData] = useState({});
  const { logout, authenticated, jwt } = useAuthContext();

  // async function fetchUsers() {
  //   const result = await getUsers({ jwt, refresh, logout });
  //   if (result.error) {
  //     console.log(`error getting users`, result);
  //   } else {
  //     console.log(`got users`, result);
  //     setUsers(result.users);
  //   }
  // }

  useEffect(() => {
    if (!jwt) return;
    // fetchUsers();
  }, [jwt]);

  function handleChange(e) {
    // console.log(e.target.value);
    setFormData({ ...formData, [e.target.id]: e.target.value });
  }

  function save() {
    console.log(`Saving ${formData.text}`);
    console.log(formData);
  }

  return (
    authenticated && (
      <Card>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="align-items-center justify-content-between">
            <Col lg={12} className="ps-lg-4 my-5 text-center text-lg-start">
              <h3 className="text-primary">New TempText</h3>
              <p className="lead">
                Anything typed below will appear in your text.
                <Button variant="falcon-primary" onClick={logout}>
                  Logout
                </Button>
              </p>
              <Form.Control
                as="textarea"
                rows={12}
                className="mb-2"
                value={formData?.text}
                id="text"
                onChange={handleChange}
              />
              <Button variant="falcon-primary" onClick={save}>
                Save
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default Create;
