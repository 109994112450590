export const refreshMinutes = 15 * 60; // fifteen minutes' worth of seconds
export const passcodeService = process.env.REACT_APP_PASSCODE_SERVICE;
export const botpoisonKey = 'pk_fe930078-0d0f-4c65-b50f-15e8d412b642';

export const userService = passcodeService;
export const thingService = passcodeService;

export const thingText = {
  singular: 'text',
  plural: 'texts',
  labelSingular: 'Text Template',
  labelPlural: 'Text Templates',
  listAttributes: ['id', 'name', 'description'],
  attributes: {
    id: {
      type: 'positive integer',
      label: 'ID',
      // readOnly: true // NOTE: treat id as special - can enter for new but can't edit (yet)
      description:
        'The unique identifier of the text sequence. Used to identify the specific text template in the wizard.',
      viewStyle: { fontWeight: 'bold' }
      // editRestrictions: {
      //   requiresSuperuser: true
      // }
    },
    name: {
      type: 'text',
      label: 'Name',
      description: 'A "friendly name" used internally to refer to the text.'
    },
    description: {
      type: 'multi-line',
      label: 'Description',
      description:
        'A more detailed description of the text, including its business purpose.',
      rows: 3
    },
    textContents: {
      type: 'multi-line',
      label: 'Text Contents',
      description:
        'The actual text wording that will be sent to the homeowner.',
      rows: 5,
      replacementTags: [
        {
          tagName: '{{pcFirstName}}',
          description: 'First name of the Project Coordinator'
        },
        {
          tagName: '{{link}}',
          description: "Link to the homeowner's wizard page"
        }
      ]
    },
    textActivity: {
      type: 'moraware activity type',
      label: 'Text Activity',
      description:
        'The id of the Activity Type in Moraware that will be used to track the progress of the text.',
      editRestrictions: {
        requiresSuperuser: true
      }
    }
  }
};
