import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'si/context/Context';
import Botpoison from '@botpoison/browser';
import { jwtDecode } from 'jwt-decode';
import { botpoisonKey, passcodeService, refreshMinutes } from 'si/config';

// do the botpoison challenge and send email
async function doChallengeAndSend(path, data) {
  // do Botpoison challenge
  const botpoison = new Botpoison({
    publicKey: botpoisonKey
  });

  console.time('challenge');
  const { solution } = await botpoison.challenge();
  console.timeEnd('challenge');
  // console.log(solution);

  // call api with email
  const uri = `${passcodeService}${path}`;
  const body = JSON.stringify({
    ...data,
    solution
  });
  console.log({ uri, body });
  const result = await fetch(uri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  });
  if (result.ok) {
    const json = await result.json();
    return json;
  } else {
    return { error: result.statusText };
  }
}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [jwt, setJwt] = useState('');
  const [authenticated, setAuthenticated] = useState(true);
  const [decoded, setDecoded] = useState({});

  useEffect(() => {
    console.log('AuthProvider useEffect');
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      console.log('jwt found');
      setJwt(jwt);
      setAuthenticated(true);
      refresh(jwt);
      return;
    }
    console.log('no jwt found');
    setAuthenticated(false);
  }, []);

  useEffect(() => {
    if (!jwt) {
      setDecoded({});
    } else {
      try {
        setDecoded(jwtDecode(jwt));
      } catch (e) {
        console.log('error decoding jwt', e, jwt);
        setDecoded({});
      }
    }
  }, [jwt]);

  useEffect(() => {
    console.log('decoded changed', decoded);
  }, [decoded]);

  const sendEmail = async email => {
    console.log(email);
    setUser({ ...user, email });
    doChallengeAndSend('/auth/send-code', { email });
  };

  const sendPasscode = async passcode => {
    const { email } = user;
    console.log({ email, passcode });
    if (!email) return false;

    const result = await doChallengeAndSend('/auth/session-from-code', {
      email,
      code: parseInt(passcode)
    });

    if (!result?.token) return false;

    // don't really need to validate the token, since a bad token will just fail lately

    localStorage.setItem('jwt', result.token);
    setJwt(result.token);
    setAuthenticated(true);

    return true;
  };

  const refresh = async (token = jwt) => {
    if (!token) return;
    // see if jwt is past refresh time
    let decoded;
    try {
      decoded = jwtDecode(token);
    } catch {
      decoded = {};
    }
    console.log(decoded);
    const { iat } = decoded;
    const now = Date.now() / 1000; // seconds only
    const diff = now - iat;
    const shouldRefresh = !iat || diff > refreshMinutes;
    if (shouldRefresh) {
      console.log('refreshing', {
        iat,
        now,
        diff,
        fifteenMinutes: refreshMinutes,
        shouldRefresh
      });

      // call api with email
      const uri = `${passcodeService}/auth/refresh`;
      console.log({ uri });
      const result = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      if (result.ok) {
        const json = await result.json();
        localStorage.setItem('jwt', json.token);
        setJwt(json.token);
        setAuthenticated(true);
        return json;
      } else if (result.status === 403) {
        logout();
        return { error: result.statusText };
      } else {
        console.log(`refresh error (but don't logout yet)`, result);
        return { error: result.statusText };
      }
    } else {
      console.log('not time to refresh', {
        iat,
        now,
        diff,
        fifteenMinutes: refreshMinutes,
        shouldRefresh
      });
    }
  };

  const logout = async () => {
    // comment out next line (removing from local storage) while debugging
    localStorage.removeItem('jwt');
    setAuthenticated(false);
    setJwt('');
  };

  const isAdmin = () => {
    return decoded?.properties?.admin;
  };

  const isSuperuser = () => {
    return decoded?.properties?.superuser;
  };

  const userEmail = () => {
    return decoded?.properties?.email;
  };

  const value = {
    user,
    authenticated,
    jwt,
    sendEmail,
    sendPasscode,
    refresh,
    logout,
    isAdmin,
    isSuperuser,
    userEmail
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = { children: PropTypes.node.isRequired };

export const useAuthContext = () => useContext(AuthContext);
export default AuthProvider;
