import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import { version } from 'config';
// import * as packageJson from '/package.json';

// get version from package.json
const version = '2.0.0';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
          <a href="https://simpleintegrations.com">Simple Integrations</a>
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
