import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Spinner, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useApiContext } from 'si/api/ApiProvider';
import { useAuthContext } from 'si/auth/AuthProvider';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { TooltipLabel } from 'si/components/common/TooltipLabel';
import { ThingInput } from './ThingInput';

const ThingEdit = ({ thingSpec, type }) => {
  const { attributes } = thingSpec;

  // get the id from the path
  const { id } = useParams();

  // set up state
  const [thingData, setThingData] = useState({ id });
  const [loadingOne, setLoadingOne] = useState(false);

  const { getThing } = useApiContext();
  const { jwt } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!jwt) return;
    fetchThing();
  }, [jwt]);

  async function fetchThing() {
    setLoadingOne(true);
    const result = await getThing(type, id);
    setLoadingOne(false);
    if (result.error) {
      console.log(`error getting thing ${type} ${id}`, result);
    } else {
      console.log(`got thing ${type} ${id}`, result);
      setThingData({ id, ...result.data });
    }
  }

  function editUser(e) {
    console.log('editUser', e);
    e.preventDefault();
    navigate(`./../../${type}/edit/${id}`);
  }

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center flex-column flex-sm-row">
          <Col sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <span style={{ textTransform: 'capitalize' }}>
                {thingSpec.labelSingular} Details
                {loadingOne && (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="mx-3 text-primary"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
              </span>
            </h5>
          </Col>
          <Col sm="auto" className="">
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="cloud"
                transform="shrink-3"
                onClick={editUser}
                disabled={loadingOne}
              >
                <span className="ms-1 text-primary">
                  Save{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {thingSpec.labelSingular}
                  </span>
                </span>
              </IconButton>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="overflow-hidden p-lg-6">
        {Object.keys(attributes).map(attributeName => {
          const attribute = attributes[attributeName];
          return (
            <Row key={attributeName} className="">
              <Form.Group>
                <Col className="">
                  <TooltipLabel
                    attributeName={attributeName}
                    attribute={attribute}
                  />
                  {attributeName === 'id' && (
                    <>
                      :{' '}
                      <span
                        className="text-primary-emphasis"
                        style={{ fontWeight: 'bold' }}
                      >
                        {thingData[attributeName]}
                      </span>
                    </>
                  )}
                </Col>
                {attributeName !== 'id' && (
                  <Col className="">
                    <div className="mb-3" style={attribute.editStyle}>
                      <ThingInput
                        attributeName={attributeName}
                        attribute={attribute}
                        thingData={thingData}
                        setThingData={setThingData}
                      />
                    </div>
                  </Col>
                )}
              </Form.Group>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

ThingEdit.propTypes = {
  thingSpec: PropTypes.object,
  type: PropTypes.string
};

export default ThingEdit;
