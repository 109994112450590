import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export default function LoaderButton({ loading, disabled, ...props }) {
  // console.log(`loading: ${loading}`);
  return (
    <Button disabled={disabled || loading} {...props}>
      {loading && (
        <>
          <FontAwesomeIcon icon="sync-alt" className="fa-spin" />{' '}
        </>
      )}
      {props.children}
    </Button>
  );
}

LoaderButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
};

// LoaderButton.defaultProps = { l: 'auth', width: 58 };
