import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from './AuthProvider';
import platform from 'platform';
import { v4 } from 'uuid';

const Email = () => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    remember: false
  });

  const navigate = useNavigate();
  const { sendEmail } = useAuthContext();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    // send the email, via auth
    sendEmail(formData.email);

    // go to passcode
    navigate('/auth/passcode');
  };

  const handleFieldChange = e => {
    // console.log(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    console.log(platform);
    console.log(v4());
  }, []);

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Passcode login</h5>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {<Form.Label>Step 1 - enter email</Form.Label>}
          <Form.Control
            placeholder={'Email address'}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="email"
          />
        </Form.Group>

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.email}
          >
            Send me passcode
          </Button>
        </Form.Group>
      </Form>
      <p>
        {platform.name}{' '}
        {platform.product || platform.os?.family || platform.manufacturer}
      </p>
    </>
  );
};

export default Email;
