import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';

const Library = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">Library</h3>
            <p className="lead">Each library type will go here.</p>
            <p>
              I'll migrate library data, section by section.{' '}
              <strong>Everything</strong> will be data-driven.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Library;
