import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { canEdit } from 'util/canEdit.ts';
import { useAuthContext } from 'si/auth/AuthProvider';

export const ThingInput = ({
  attributeName,
  attribute,
  thingData,
  setThingData
}) => {
  const authContext = useAuthContext();

  if (attributeName === 'id') {
    return <span style={attribute.viewStyle}>{thingData.id}</span>;
  }
  const placeholder = attribute.description;
  const value = thingData[attributeName] || '';
  // const errors = {}; TODO: ERROR MSG HANDLING!!
  // console.log({ attributeName, placeholder, value });

  if (!canEdit(attribute, authContext))
    return value ? (
      <span>{value}</span>
    ) : (
      <span className="text-400" style={{ fontStyle: 'italic' }}>
        {placeholder}
      </span>
    );

  const onChange = e => {
    setThingData({ ...thingData, [attributeName]: e.target.value });
  };

  if (attribute.type === 'multi-line') {
    const replacementHeading = attribute.replacementTags && (
      <h6>Available Replacement Tags:</h6>
    );
    const replacementText = (attribute.replacementTags &&
      attribute.replacementTags.map((t, index) => (
        <p key={index} className="fs--1">
          <span className="text-danger">{t.tagName}</span>: {t.description}
        </p>
      ))) || <></>;
    return (
      <>
        {replacementHeading}
        {replacementText}
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          rows={attribute.rows || 3}
        />
      </>
    );
  }

  return (
    <Form.Control
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      // isInvalid={errors[name]}
      // isValid={Object.keys(errors).length > 0 && !errors[name]}
    />
  );
};

ThingInput.propTypes = {
  attributeName: PropTypes.string.isRequired,
  attribute: PropTypes.object.isRequired,
  thingData: PropTypes.object.isRequired,
  setThingData: PropTypes.func.isRequired
};
