import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import ErrorLayout from '../overrides/layouts/ErrorLayout';
import Starter from 'components/pages/Starter';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import MainLayout from '../overrides/layouts/MainLayout';
import Create from 'si/components/pages/Create';
import AuthSimpleLayout from '../overrides/layouts/AuthSimpleLayout';
import Email from 'si/auth/Email';
import Passcode from 'si/auth/Passcode';
import Failed from 'si/auth/Failed';
import UserTable from 'si/components/pages/users/UserTable';
import Default from 'si/components/pages/dashboards/Default';
import Analytics from 'si/components/pages/dashboards/Analytics';
import LiveJobs from 'si/components/pages/LiveJobs';
import DocsOverview from 'si/components/pages/docs/Overview';
import Library from 'si/components/pages/library/Library';
import ThingList from 'si/components/pages/things/ThingList';
import { thingText } from 'si/config';
import Texts from 'si/components/pages/config/Texts';
import ThingView from 'si/components/pages/things/ThingView';
import ThingEdit from 'si/components/pages/things/ThingEdit';

const SIRoutes = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* //--- MainLayout Starts  */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<Default />} />
          <Route path="/dashboard" element={<Default />} />
          <Route path="/dashboard/analytics" element={<Analytics />} />
          <Route path="/jobs" element={<LiveJobs />} />
          <Route path="/library/material-types" element={<Library />} />
          <Route
            path="/config/texts"
            element={<ThingList thingSpec={thingText} />}
          />
          <Route
            path="/config/text/:id"
            element={<ThingView thingSpec={thingText} type={'text'} />}
          />
          <Route
            path="/config/text/edit/:id"
            element={<ThingEdit thingSpec={thingText} type={'text'} />}
          />
          <Route
            path="/config/texts/new"
            element={<Texts thingSpec={thingText} />}
          />
          <Route path="/docs/overview" element={<DocsOverview />} />
          <Route path="/starter" element={<Starter />} />
          <Route path="/create" element={<Create />} />
          <Route path="/users" element={<UserTable />} />
        </Route>
        {/* //--- MainLayout end  */}

        {/* // AuthLayout - having them here prevents flickering on refreshes */}
        <Route element={<AuthSimpleLayout />}>
          <Route path="auth/email" element={<Email />} />
          <Route path="auth/passcode" element={<Passcode />} />
          <Route path="auth/failed" element={<Failed />} />
        </Route>

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
    </>
  );
};

export default SIRoutes;
