import React from 'react';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

export const TooltipLabel = ({
  attributeName,
  attribute,
  noForm = false,
  children
}) => {
  const { label, description } = attribute;

  return (
    <OverlayTrigger
      key={`overlay-trigger-${attributeName}`}
      placement="right"
      overlay={
        <Tooltip className="mx-1" id={`tooltip-${attributeName}`}>
          {description}
        </Tooltip>
      }
    >
      <a>
        {noForm ? (
          <span>{attribute.label}</span>
        ) : (
          <Form.Label>{label}</Form.Label>
        )}
        {children}
      </a>
    </OverlayTrigger>
  );
};

TooltipLabel.propTypes = {
  attributeName: PropTypes.string.isRequired,
  attribute: PropTypes.object.isRequired,
  noForm: PropTypes.bool,
  children: PropTypes.node
};
