import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiContext } from 'si/context/Context';
import { thingService, userService } from 'si/config';
import { useAuthContext } from 'si/auth/AuthProvider';

const ApiProvider = ({ children }) => {
  const { authenticated, jwt, refresh, logout } = useAuthContext();

  async function callApi(service, path, method, body) {
    if (!authenticated) {
      throw new Error('not authenticated');
    }
    const uri = `${service}${path}`;
    console.log({ uri, jwt });
    const result = await fetch(uri, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body
    });
    if (result.ok) {
      const json = await result.json();
      refresh();
      return json;
    } else if (result.status === 403) {
      // console.log('error 403 - would have logged out');
      logout();
      return { error: result.statusText || true };
    } else {
      return { error: result.statusText || true };
    }
  }

  // user api

  const getUsers = async () => {
    return await callApi(userService, '/users', 'GET');
  };

  const getUser = async email => {
    return await callApi(userService, `/user/${email}`, 'GET');
  };

  const saveUser = async user => {
    return await callApi(userService, '/user', 'POST', JSON.stringify(user));
  };

  const deleteUser = async email => {
    return await callApi(userService, `/user/${email}`, 'DELETE');
  };

  // thing api

  const getThings = async type => {
    return await callApi(thingService, `/things/${type}`, 'GET');
  };

  const getThing = async (type, id) => {
    return await callApi(thingService, `/thing/${type}/${id}`, 'GET');
  };

  const saveThing = async (type, id, thing) => {
    return await callApi(
      thingService,
      `/thing/${type}/${id}`,
      'POST',
      JSON.stringify(thing)
    );
  };

  const deleteThing = async (type, id) => {
    return await callApi(thingService, `/thing/${type}/${id}`, 'DELETE');
  };

  const value = {
    getUsers,
    getUser,
    saveUser,
    deleteUser,
    getThings,
    getThing,
    saveThing,
    deleteThing
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = { children: PropTypes.node.isRequired };

export const useApiContext = () => useContext(ApiContext);

export default ApiProvider;
